<template>
    <div class="box">
        <div class="conte">
            <p style="padding: 10px 15px;">
                <i class="fas fa-exclamation-triangle"></i>
                You want to change the start of the numeration of your Invoices?.
                Do it now or click Cancel.
            </p>
            <button class="btn btn-success" style="margin-right: 5px" @click="activateStartUpdate">
                Update Start Number
            </button>
            <button class="btn btn-primary" @click="activateStartInvoice">
                Cancel
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: "UpdateNumdoc",
    data() {
        return {
            start_invoice: false,
        }
    },
    methods: {
        activateStartUpdate() {
            this.start_invoice = true;
            this.$emit('wantToUpdateNumDoc', this.start_invoice);
        },
        activateStartInvoice() {
            this.start_invoice = false;
            this.$emit('wantToUpdateNumDoc', this.start_invoice);
        }
    }
};
</script>
<style lang="scss" scoped>
.conte {padding: 15px;}
</style>
