<template>
    <div class="container-fluid invoForm">
        <div v-show="isLoading" class="loading">
            <Loader></Loader>
        </div>
        <div class="lockStartNumber" v-bind:class="{ show: start_invoice }">
            <update-numdoc v-show="start_invoice" @wantToUpdateNumDoc="openUpdateNumDoc" />
        </div>
        <div class="card formContent">
            <div class="alert alert-danger" role="alert" v-if="errorSave.length">
                <ul style="margin-bottom: 0">
                    <li v-for="error in errorSave" :key="error">{{ error }}</li>
                </ul>
            </div>
            <div class="card-body body-form">
                <div class="row" id="btnsTop">
                    <div class="col-6 align-self-center">
                        <h3 class="page-title">
                            {{ form.titlePage }}
                            <span v-if="form.numdoc != null"># {{ form.numdoc }}
                                <button @click="openNotes(form.idInv)" type="button" title="Edit Notes"
                                    class="btn btn-secundary" style="padding: 0px 3px; font-size: 10px; height: 16px;">
                                    <i class="far fa-sticky-note"></i>
                                    Edit Note
                                </button>
                            </span>
                        </h3>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-success" :disabled="saveDisabled" @click="saveInvoice()">
                            <i class="fas fa-save"></i> SAVE
                        </button>
                        <button type="button" class="btn btn-secundary" style="margin-right: 5px; margin-left: 5px" @click="returnInvoice()">
                            <i class="fas fa-times"></i> CANCEL
                        </button>
                        <button type="button" class="btn btn-danger" @click="deleteInvoice(form.idInv)" v-if="form.actionNname == 'update'">
                            <i class="fas fa-trash-alt"></i> DELETE
                        </button>
                    </div>
                </div>
                <div class="row" id="customer_estInfo_date_status">
                    <selectCustomer
                        v-if="loadInvByID"
                        :controlLoad="loadInvByID"
                        :propForm="form"
                        @addNewCustomer="popAddCustomer"
                        @sDS="setDateStorage($event)"
                        @loadCustomer="isLoadingCustomer($event)"
                        @updateProps="changeCustomer($event)"
                    />
                    <invoname-jobinfo
                        v-if="loadInvByID"
                        :controlLoad="loadInvByID"
                        :propdocument_name="form.invoicename"
                        :propjob_information="form.jobinformation"
                        :propfecdoc="form.fecdoc"
                        :propfecdue="form.fecdue"
                        :propForm="form"
                        :propdisabledSteps="disabledSteps"
                        @updateProps="updateJobInfo($event)"
                        @loadingData="loadOnHold"
                    />
                </div>
                <div id="sec-products">
                    <table_ePro
                        v-if="loadInvByID"
                        :controlLoad="loadInvByID"
                        :propdisabledSteps="disabledSteps"
                        :propForm="form"
                        :propItemsprod="itemsprod"
                        :proptaxShow="taxShow"
                        :propgroupShow="groupShow"
                        @addProdct="addNewProducts"
                        @updateProdcts="setProd"
                        @loadingData="loadOnHold"
                    />
                </div>
                <div v-if="!disabledSteps.customer" class="row" id="customerMessage-totales">
                    <customerMessage
                        v-if="loadInvByID"
                        :controlLoad="loadInvByID"
                        :propdisabledSteps="disabledSteps"
                        :propForm="form"
                        @updateProps="setMessage"
                    />
                    <div class="col-7">
                        <resumenTotal
                            v-if="loadInvByID"
                            :controlLoad="loadInvByID"
                            :propdisabledSteps="disabledSteps"
                            :propForm="form"
                            :propTaxesResumen="taxesResumen"
                            :propItemsprod="itemProducts"
                            :proptaxShow="taxShow"
                            :propgroupShow="groupShow"
                            @setAlertError="setAlert"
                            @updateTotales="setTotales"
                        />
                    </div>
                </div>
                <div v-if="!disabledSteps.customer" class="row" style="margin-top: 20px;" id="btnsDow">
                    <div class="col-6 text-left"></div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-success" :disabled="saveDisabled" @click="saveInvoice()">
                            <i class="fas fa-save"></i> SAVE
                        </button>
                        <button type="button" class="btn btn-secundary" style="margin-right: 5px; margin-left: 5px" @click="returnInvoice()">
                            <i class="fas fa-times"></i> CANCEL
                        </button>
                        <button type="button" class="btn btn-danger" @click="deleteInvoice(form.idInv)" v-if="form.actionNname == 'update'">
                            <i class="fas fa-trash-alt"></i> DELETE
                        </button>
                    </div>
                </div>
            </div> <!-- card-body -->
        </div>
        <!-- modal -->
        <modalLateral v-if="validOpen">
            <component v-bind:is="m.component"></component>
        </modalLateral>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import Loader from '@/core/components/shared/Loader';
import './css/form.scss';
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import UpdateNumdoc from '@/apps/billing/components/invoices/iForm/UpdateNumdoc';
import StartInvoice from '@/apps/billing/components/setting/StartInvoice';
import NotesAdmin from '@/apps/billing/components/NotesAdmin'
import formCustomerComp from '@/apps/billing/components/formCustomer';
import selectCustomer from '@/apps/billing/components/estpro/secForm/selectCustomer.vue';
import table_ePro from '@/apps/billing/components/estpro/secForm/table_ePro.vue';
import formProducts from '@/apps/billing/components/formProducts';
import customerMessage from '@/apps/billing/components/estpro/secForm/customerMessage.vue';
import resumenTotal from '@/apps/billing/components/estpro/secForm/resumenTotal.vue';
import invoName_jobInfo from '../../components/invoices/iForm/invoName_jobInfo.vue';
export default {
    name: "InvoForm",
    display: "Table",
    order: 8,
    components: {
        Loader,
        modalLateral,
        "update-numdoc": UpdateNumdoc,
        StartInvoice,
        NotesAdmin,
        formCustomerComp,
        selectCustomer,
        'invoname-jobinfo': invoName_jobInfo,
        table_ePro,
        formProducts,
        customerMessage,
        resumenTotal,
    },
    data() {
        return {
            isLoading: true,
            m: {
                show: false,
                component: '',
                data: []
            },
            list: [],
            dragging: false,
            isLoadingstart: false,
            errorSave: [],
            showModal: false,
            ProdServiceLists: [],
            CustomersList: [],
            TaxesLists: [],
            SettingBox: "",
            start_invoice: false,
            formList: {
                items: [],
            },
            TermsList: [],
            ValuesCustomers: {
                text: null,
                value: null,
                billing_address: null,
                bussinesname: null,
                customer_name: null,
                customer_lastname: null,
                customer_phone: null,
                customer_officephone: null,
                customer_address: null,
                email: null,
                city: null
            },
            formcustomer: {
                CustomerId: null,
                firstname: null,
                email: null,
                address: null,
                state: null,
                lastname: null,
                phone: null,
                city: null,
                zip: null,
                error: 0,
            },
            form: {
                idInv: null,
                numdoc: null,
                titlePage: "Add Invoice",
                btnName: "SAVE",
                actionNname: "create", // update
                customer_id: null,
                customer_name: null,
                customer_email: null,
                customer_address: null,
                billing_address: null,
                customer_message: null,
                term_id: 0,
                invoicename: null,
                jobinfomationcheck: true,
                jobinformationTitle: "Job information",
                jobinformation: null,
                jobinformation_text: null,
                subtotal: 0,
                discount_type: 1,
                discount_mount: 0.00,
                discount_total: 0.00,
                discount_name: '',
                total: 0,
                fecdoc: new Date().toISOString(),
                fecdue: new Date().toISOString(),
                totaltax: null,
                qty: null,
                dataItems: [],
            },
            FormAddPS: {
                vatError: false,
                vatErrorMsg: null,
                index_modal: null,
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 1.00,
                description: null,
                applytax: 0,
                status: 0,
            },
            saveDisabled: false,
            modalBill: [
                'NotesAdmin',
                'formCustomerComp',
                'formProducts',
                'StartInvoice',
            ],
            PlanPremium: (window.OrgPlan > 1),
            statusLoadName: [
                ['has not loaded'],
                ['loading'],
                ['success'],
                ['faild'],
                ['error'],
                ['is already configured'],
            ],
            stateLoadDOM: [
                {"name": "metstartInvoice", 'status': '0'},
                {"name": "getCustomer", 'status': '0'},
                {"name": "getTermsList", 'status': '0'},
                {"name": "getInvoiceSetting", 'status': '0'},
                {"name": "getAccountTypes", 'status': '0'},
                {"name": "getTaxes", 'status': '0'},
                {"name": "getProdservices", 'status': '0'},
            ],
            disabledSteps:{
                startNumer: true,
                customer: true,
                nameEst: true,
                jobsInfo: true,
                products: true,
            },
            errorsLoad: [],
            loadInvByID: false,
            itemProducts: [],
            itemsprod: [],
            taxesResumen: [],
            taxShow: 1,
            groupShow: 0,
        };
    },
    directives: { ClickOutside },
    beforeMount() { },
    mounted() {
        let t = this;
        t.init();
    },
    created() {
        this.printNoti(4, 'Loading...', 'Please wait while data is loaded', -1)
        // console.log('status', this.statusLoadName)
    },
    methods: {
        methodInLoad(myMethod, setStatus){
            let t = this;
            let object = t.stateLoadDOM;
            for (const key in object) {
                if (Object.prototype.hasOwnProperty.call(object, key)) {
                    const element = object[key];
                    const exists = (element.name==myMethod);
                    if(exists){
                        if (element.status !== undefined) { element.status = setStatus; }
                        if(setStatus == 1){
                            // console.log('stateLoadDOM', myMethod, 'Status='+t.statusLoadName[setStatus], new Date().getTime());
                        }
                        else if(setStatus == 3 || setStatus == 4){
                            let msj = 'Property '+myMethod+' '+t.statusLoadName[setStatus];
                            t.errorsLoad.push(msj);
                            // console.log('stateLoadDOM', myMethod, 'Status='+t.statusLoadName[setStatus]);
                        }else if(setStatus == 2){
                            // console.log('stateLoadDOM', myMethod, 'Status='+t.statusLoadName[setStatus]);
                        }
                    }
                }
            }
        },
        printNoti(type, title, text, duration){
            let t=this;
            // console.log('printNoti', type, title, text, duration)
            t.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: (type!=undefined) ? type : '1', // 1=success|2=info|3=error|4=loading|5=warning
                title: (title!=undefined) ? title : 'success',
                text: (text!=undefined) ? text : 'Successful registration.',
                duration: (duration!=undefined && typeof duration === 'number') ? duration : 3000,
                // duration debe ser siempre Numerico sino por defecto 3000, -1 para dejar permanente hasta que cierre manualemtne
            });
        },
        clearPrintNoti(){
            this.$store.commit("core/setNotiPushDestroyAll"); // limpiar todas las notificaciones
        },
        init() {
            let t = this;
            t.PlanPremium = (t.$store.getters['core/Org/getPlanId'] > 1);
            t.metstartInvoice().then(updateNum => {
                if (updateNum) {}
                // leer parametor de url para saber que accion realizar
                // console.log('$route.params', t.$route.params)
                t.forOrgRoute();
                if (t.$route.params.hasOwnProperty('edit') && (t.$route.params.hasOwnProperty('idinv') && parseInt(t.$route.params.idinv)>0)) {
                    t.form.titlePage = "Edit Invoice";
                    t.form.actionNname = "update";
                    t.stateLoadDOM.push({"name": "datosFacturables", 'status': '0'})
                    t.getInvoiceById(1);
                }
                else if (t.$route.params.hasOwnProperty('duplicate') && (t.$route.params.hasOwnProperty('idinv') && parseInt(t.$route.params.idinv)>0)) {
                    t.form.titlePage = "Duplicate Invoice";
                    t.form.actionNname = "duplicate";
                    t.stateLoadDOM.push({"name": "datosFacturables", 'status': '0'})
                    t.getInvoiceById(2);
                }
                else if (t.$route.params.hasOwnProperty('idest') && parseInt(t.$route.params.idest)>0) {
                    t.form.titlePage = "Convert Estimate to Invoice";
                    t.form.actionNname = "convertEstimate";
                    t.stateLoadDOM.push({"name": "datosFacturables", 'status': '0'});
                    // console.log('getEstimateById')
                    t.getEstimateById();
                }
                else if (t.$route.name == 'InvoProFormcustomerID' && t.$route.params.idc){
                    t.form.customer_id = t.$route.params.idc;
                    console.log('InvoProFormcustomerID', t.form.customer_id)
                    // t.stateLoadDOM.push({"name": "datosFacturables", 'status': '4'})
                    t.loadInvByID = true;
                }
                else{
                    // create new
                    t.loadInvByID = true;
                }
            });
        },
        // validar memoria de datos no tan volatil
        forOrgRoute(){
            if(localStorage.getItem('OrgRouteCurrent')==null){
                localStorage.setItem('OrgRouteCurrent', this.$route.name+'/'+ localStorage.getItem('mark_client_data'));
            }
            if(localStorage.getItem('OrgRouteCurrent')!=localStorage.getItem('OrgRoute')){
                // vaciar datos en memoria
                localStorage.setItem('OrgRoute', localStorage.getItem('OrgRouteCurrent'));
                localStorage.removeItem('ProdServiceLists');
                localStorage.removeItem('CustomersList');
            }
        },
        metstartInvoice() {
            let t = this;
            let myMethod = 'metstartInvoice';
            t.methodInLoad(myMethod, 1);// loading
            return new Promise((resolve) => {
                window.billing
                .get("startinvoice/" + window.localuserdata)
                .then((response) => {
                    // console.log(response.data);
                    t.methodInLoad(myMethod, 2);// success
                    if (response.data.start_invoice == false) {
                        t.start_invoice = true; // sino esta configurado
                    } 
                    resolve(true);
                })
                .catch((error) => {
                    t.methodInLoad(myMethod, 4);// error
                    console.log(error);
                    resolve(false);
                });
            });
        },
        openUpdateNumDoc(open) {
            let t = this;
            t.start_invoice = false;
            if(open){
                t.openModal('StartInvoice', null);
            }
        },
        openModal(nomComp, data){
            let t = this;
            t.m = {
                show: true,
                component: nomComp,
                data: data
            }
            setTimeout(() => {
                t.$store.commit('core/setOpenModal', t.m);
            }, 400);
        },
        openNotes(id){
            let t = this;
            t.openModal('NotesAdmin', {
                id:id,
                type:'Invoice',
                transac_type:'Invoice',
                idNote: id
            });
        },
        returnInvoice() {
            this.clearPrintNoti();
            this.$router.push({ name: "Invoices" });
        },
        // validar cargas desde storage
        setDateStorage(varDate){
            let fecha = new Date().getFullYear()+'/'+(new Date().getMonth()+1)+'/'+new Date().getDate();
            let hora = new Date().getHours()+':'+new Date().getMinutes()+':0';
            let dateStorage = fecha+' '+hora;
            localStorage.setItem(varDate, dateStorage);
        },
        // abrir pop de add new customer
        popAddCustomer() {
            let t = this;
            t.openModal('formCustomerComp', null);
        },
        isLoadingCustomer(status) {
            let t = this;
            // console.log('isLoadingCustomer', status)
            t.isLoadCustomer = status
            let myMethod = 'getCustomer';
            switch(status){
                case 'pending':
                    t.methodInLoad(myMethod, 1);// loading
                    break;
                case 'finish':
                    t.methodInLoad(myMethod, 2);// success
                    break;
                case 'error':
                    t.methodInLoad(myMethod, 4);// error
                    break;
            }
        },
        changeCustomer(c) {
            let t = this;
            // console.log('form.customer_id', c)
            t.form.customer_id = c.selected.value
            t.form.customer_name = c.selected.text
            t.form.customer_email = c.selected.email
            t.form.customer_address = c.selected.customer_address
        },
        // actualizar job information
        updateJobInfo(d){
            let t= this;
            // console.log('updateJobInfo', d)
            t.form.invoicename = d.document_name
            t.form.jobinformation_text = d.job_information
            t.form.term_id = d.term_id
            t.form.fecdue = d.due_date;
            t.form.fecdoc = d.dateDoc;
            // console.log('fecdoc', t.form.fecdoc)
            // console.log('fecdue', t.form.fecdue)
        },
        // nuevo producto, seleccionar producto
        addNewProducts(d) {
            let t = this;
            t.openModal('formProducts', d);
        },
        setProd(d){
            let t = this;
            // console.log('setProd', d)
            t.itemProducts = d.itemProducts;
            t.taxShow = d.taxShow;
            t.groupShow = d.groupShow;
            t.taxesResumen = d.taxesResumen;
        },
        loadOnHold(e){
            let t = this;
            let myMethod = e.name;
            let status = e.status;
            switch(status){
                case 'pending':
                    t.methodInLoad(myMethod, 1);// loading
                    break;
                case 'finish':
                    t.methodInLoad(myMethod, 2);// success
                    break;
                case 'faild':
                    t.methodInLoad(myMethod, 3);// faild
                    break;
                case 'error':
                    t.methodInLoad(myMethod, 4);// error
                    break;
            }
        },
        // customer message
        setMessage(customer_message){
            let t = this;
            // console.log('setMessage', customer_message)
            t.form.customer_message = customer_message;
        },
        // alert error para totales
        setAlert(msj){
            // console.log('setAlertError', msj)
            this.printNoti(3, "Error!", msj, 6000);
        },
        // set totales, leer datos de totales
        setTotales(d){
            let t = this;
            // console.log('setTotales', d)
            t.form.subtotal = d.subtotal;
            t.form.discount_type = d.discount_type;
            t.form.discount_mount = d.discount_mount;
            t.form.discount_total = d.discount_total;
            t.form.discount_name = d.discount_name;
            t.form.totaltax = d.totaltax;
            t.form.total = d.total;
            // console.log('setTotales', t.form)
        },
        getInvoiceById(status) {
            let t = this;
            t.methodInLoad('datosFacturables', 1);// loading
            window.billing
            .get("invoice/" + t.$route.params.idinv)
            .then((response) => {
                let dataR = response.data;
                let invItem = dataR.result;
                let invCustomer = dataR.customer;
                t.form.idInv = invItem.id;
                t.form.numdoc = invItem.numdoc;
                t.form.customer_id = invCustomer.id;
                t.form.jobinformation = invItem.jobinformation;
                t.form.invoicename = invItem.invoicename;
                t.form.jobinformation_text = invItem.jobinformation_text;
                t.form.customer_message = invItem.customer_message;
                t.form.term_id = invItem.terms_id;
                t.form.fecdoc = (status==2) ? new Date().toISOString().slice(0, 10) : invItem.fecdoc;
                t.form.fecdue = (status==2) ? new Date().toISOString().slice(0, 10) : invItem.fecdue;
                t.form.subtotal = invItem.subtotal;
                t.form.discount_type = invItem.discount_type;
                t.form.discount_mount = invItem.discount_mount;
                t.form.discount_total = invItem.discount_total;
                t.form.discount_name = invItem.discount_name;
                t.form.total = invItem.total;
                let tableProducts = [];
                for (let item in dataR.itemsprod) {
                    let setItem = dataR.itemsprod[item];
                    setItem.value= dataR.itemsprod[item].product_id;
                    setItem.unitcost= dataR.itemsprod[item].price;
                    setItem.text= dataR.itemsprod[item].product_name;
                    setItem.descripcion= dataR.itemsprod[item].description;
                    setItem.qty= dataR.itemsprod[item].quantity;
                    setItem.prices= dataR.itemsprod[item].total;
                    tableProducts.push(setItem);
                }
                // nuevas variables
                t.itemsprod = tableProducts;
                t.taxShow = (dataR?.taxShow!=null && dataR?.taxShow?.meta_value!=null) ? parseInt(dataR?.taxShow?.meta_value) : 1;
                t.groupShow = (dataR?.groupShow!=null && dataR?.groupShow?.meta_value!=null) ? parseInt(dataR?.groupShow?.meta_value) : 0;
                t.loadInvByID = true;
                t.methodInLoad('datosFacturables', 2);// success
                if(status==1){
                    // solo para ediciones, creamos copia de datos
                    localStorage.removeItem("dataOrigin"); // removemos datos antiguos
                    setTimeout(() => {
                        // tiempo de espera para q los datos se ajusten antes de almacenar
                        localStorage.setItem("dataOrigin", JSON.stringify(t.createDataSend()))
                    }, 600);
                }
            })
            .catch((error) => {
                console.log(error);
                t.methodInLoad('datosFacturables', 4);// error
            });
        },
        getEstimateById() {
            let t = this;
            t.methodInLoad('datosFacturables', 1);// loading
            window.billing
            .get("estimate/" + this.$route.params.idest)
            .then((response) => {
                let dataR = response.data;
                let estItem = dataR.result;
                let estCustomer = dataR.customer;
                t.form.customer_id = estCustomer.id;
                // console.log('t.form.customer_id', t.form.customer_id)
                t.form.invoicename = estItem.estimate_optional;
                t.form.jobinformation =estItem.job_information;
                // console.log('estItem', t.form.jobinformation)
                t.form.jobinformation_text =estItem.job_information;
                t.form.customer_message = estItem.customer_message;
                t.form.fecdoc = new Date().toISOString().slice(0, 10);
                t.form.fecdue = new Date().toISOString().slice(0, 10);
                t.form.subtotal = estItem.sub_total;
                t.form.total = estItem.total;
                t.form.discount_type = estItem.discount_type;
                t.form.discount_mount = estItem.discount_mount;
                t.form.discount_total = estItem.discount_total;
                t.form.discount_name = estItem.discount_name;
                t.form.subtotal = estItem.subtotal;
                t.form.discount_type = estItem.discount_type;
                t.form.discount_mount = estItem.discount_mount;
                t.form.discount_total = estItem.discount_total;
                t.form.discount_name = estItem.discount_name;
                t.form.total = estItem.total;
                let tableProducts = [];
                for (let item in dataR.itemsprod) {
                    let setItem = dataR.itemsprod[item];
                    setItem.value= dataR.itemsprod[item].product_id;
                    setItem.unitcost= dataR.itemsprod[item].unitcost;
                    setItem.text= dataR.itemsprod[item].product_name;
                    setItem.descripcion= dataR.itemsprod[item].description;
                    setItem.qty= dataR.itemsprod[item].qty;
                    setItem.prices= dataR.itemsprod[item].price;
                    tableProducts.push(setItem);
                }
                // nuevas variables
                t.itemsprod = tableProducts;
                t.taxShow = (dataR?.taxShow!=null && dataR?.taxShow?.meta_value) ? dataR?.taxShow?.meta_value : 1;
                t.groupShow = (dataR?.groupShow!=null && dataR?.groupShow?.meta_value) ? dataR?.groupShow?.meta_value : 0;
                t.loadInvByID = true;
                t.methodInLoad('datosFacturables', 2);// success
            })
            .catch((error) => {
                console.log(error);
                t.methodInLoad('datosFacturables', 4);// error
            });
        },
        deleteInvoice(idinv) {
            let t= this;
            t.isLoading = true;
            t.$swal({
                title: "Delete this Invoice?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    t.clearPrintNoti(); // limpiar notis
                    window.billing
                    .put("invoice/delete/" + idinv, {
                        user_id: window.localuserdata,
                    })
                    .then((response) => {
                        // console.log(response.data);
                        t.isLoading = false;
                        if (response.data.status) {
                            t.printNoti(1, "Success!", "Deleted Invoice Successful", 3000);
                            t.returnInvoice();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        t.printNoti(3, "Failed to delete!", "Error deleting invoice, please try again", 6000);
                        t.isLoading = false;
                    });
                }
            });
            t.isLoading = false;
        },
        // objecto que almacena los datos a enviar
        createDataSend(){
            let t = this;
            return {
                typeUser: window.user_type,
                oauth: localStorage.getItem("oauth_m"),
                org_id: window.localuserdata,
                user_idd: window.master_client,
                user_id: window.localuserdata,
                id: t.form.idInv,
                idInv: t.form.idInv,
                numdoc: t.form.numdoc,
                customer_id: t.form.customer_id,
                invoicename: t.form.invoicename,
                jobinformation: t.form.jobinformation,
                jobinformation_text: t.form.jobinformation_text,
                customer_message: t.form.customer_message,
                sub_total: t.form.subtotal,
                total: t.form.total,
                fecdoc: t.form.fecdoc,
                fecdue: t.form.fecdue,
                term_id: t.form.term_id,
                dataItems: t.itemProducts,
                discount_type: t.form.discount_type,
                discount_mount: t.form.discount_mount,
                discount_total: t.form.discount_total,
                discount_name: t.form.discount_name,
                taxShow: t.taxShow,
                groupShow: t.groupShow,
            };
        },
        // comparar si hay modificaciones antes de hacer un update
        isChangeData(sendData) {
            const original = JSON.parse(localStorage.getItem("dataOrigin"));
            if(original){
                const modifiedFields = Object.keys(original).filter(
                    key => original[key] !== sendData[key]
                );
                if (modifiedFields.length > 0) {
                    // console.log("Campos modificados:", modifiedFields);
                    return true;
                    // modifiedFields .forEach(field => {
                    //     console.log(`Campo: ${field}, Valor original: ${original[field]}, Nuevo valor: ${sendData[field]}`);
                    // });
                } else {
                    // console.log("No se modificaron campos.");
                    return false;
                }
            }
            return false;
        },
        isRequiredData(sendData){
            let t= this;
            const ignore = ["invoicename", "jobinformation", "customer_message", "jobinformation_text", "discount_name"];
            if (t.form.actionNname == "create" || t.form.actionNname == "convertEstimate" || t.form.actionNname == "duplicate"){
                ignore.push("id");
                ignore.push("idInv");
                ignore.push("numdoc");
            }
            // console.log('actionNname', t.form.actionNname)
            // console.log('ignore', ignore)
            const errors = Object.keys(sendData).filter(field => {
                let isValid = sendData[field] !== undefined && sendData[field] !== null;
                if(ignore.indexOf(field) > -1){ isValid=true;}
                if (!isValid) {
                    console.log(`El campo "${field}" no cumple con las reglas de validación.`, sendData[field]);
                    this.printNoti(5, 'Warning', `El campo "${field}" no cumple con las reglas de validación.`, -1)
                }
                return !isValid;
            });
            return (errors.length === 0)
        },
        saveInvoice(){
            // console.log('saveInvoice')
            let t= this;
            t.isLoading = true;
            t.saveDisabled = true;
            t.clearPrintNoti(); // limpiar notis
            t.errors = [];
            if (!t.form.customer_id) {
                this.printNoti(5, 'Warning', 'Must select "Customer', -1)
                t.msjAlert('Must select "Customer')
                t.isLoading = false;
                t.saveDisabled = false;
                return false;
            }
            if (t.form.total == 0) {
                // t.errors.push("Total must not be greater than zero");
                this.printNoti(5, 'Warning', 'Total must not be greater than zero', -1)
                t.isLoading = false;
                t.saveDisabled = false;
                return false;
            }
            if (parseInt(t.form.customer_id)>0 && t.errors.length == 0) {
                let sendData = t.createDataSend();
                // console.log(t.form.actionNname, sendData)
                if(!t.isRequiredData(sendData)){
                    // si hay errores cancelamos la carga
                    t.isLoading = false;
                    t.saveDisabled = false;
                }
                else if (
                    t.form.actionNname == "create" ||
                    t.form.actionNname == "convertEstimate" ||
                    t.form.actionNname == "duplicate"
                ) {
                    window.billing.post("invoice/add", sendData)
                    .then((response) => {
                        t.isLoading = false;
                        // console.log(response.data);
                        this.printNoti(1, 'Success', 'Invoice created', 5000);
                        t.saveDisabled = false;
                        t.returnInvoice();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.printNoti(3, 'Error', 'Failure to generate the invoice', -1);
                        t.isLoading = false;
                        t.saveDisabled = false;
                    });
                }
                else if (t.form.actionNname == "update") {
                    window.billing.put("invoice/edit/" + t.form.idInv, sendData)
                    .then((response) => {
                        t.isLoading = false;
                        t.saveDisabled = false;
                        // console.log(response.data);
                        this.printNoti(1, 'Success', 'Updated Invoice', 5000);
                        t.returnInvoice();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.printNoti(3, 'Error', 'Updating Invoice', -1);
                        t.isLoading = false;
                        t.saveDisabled = false;
                    });
                }
            }else {
                t.saveDisabled = false;
                t.isLoading = false;
            }
        },
    },
    watch: {
        'form.customer_id': function(){
            let t =this;
            if(t.form.customer_id>0){
                t.saveDisabled=false
                t.disabledSteps.customer=false;
                // console.log('t.form.customer_id', t.form.customer_id)
            }
            else{
                t.saveDisabled=true;
                t.disabledSteps.customer=true;
            }
        },
        // 'getOpenModal': function() {console.log('getOpenModal', this.getOpenModal)},
        // 'getActionRefresh': function () {console.log('getActionRefresh', this.getActionRefresh)},
        get_setupBilling: function () {
            if (this.get_setupBilling) {
                let g = this.$store.getters["billing/get_setupBilling"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "UpdateStartInvoice") {
                    // this.metstartInvoice();
                }
            }
        },
        inputFecdoc: function () {
            // this.formatearFecha();
            this.form.fecdoc = this.inputFecdoc;
            // this.asignarDueDate();
        },
        formcustomer: {
            deep: true,
            handler: (e) => {
                //debugger;
                for (var i in e) {
                    if (i != "notes" && i != "error") {
                        if (e[i] != null && e[i].length > 0) {
                            if (e[i].indexOf(",") > -1) {
                                //console.log(i, e[i]);
                                e.error = parseInt(e.error) + 1;
                                e[i] = e[i].replace(",", "");
                                console.log(e);
                            }
                        }
                    }
                }
            },
        },
        "formcustomer.error": function (e) {
            if (e > 0) {
                this.printNoti(3, "Error!", "The symbol coma “,” is not allowed.", 5000);
                return (e = 0);
            }
        },
        getPlanId() {
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            // console.log('watch PlanPremium', this.PlanPremium);
        },
        stateLoadDOM: {
            deep: true,
            handler: function (myObject) {
                let t = this;
                let r = true;
                let minLoadAccept = t.stateLoadDOM.length;
                // console.log('minLoadAccept', myObject)
                let totalLoad = 0;
                let totalSuccess = 0;
                for (const key in myObject) {
                    if (Object.prototype.hasOwnProperty.call(myObject, key)) {
                        totalLoad++;
                        let item = myObject[key];
                        if (item && item.status !== undefined) {
                            // console.log('item', item.name, item.status)
                            if (item.status == 2) {
                                totalSuccess++;
                                // console.log('stateLoadDOM', 'totalSuccess: '+totalSuccess, 'totalLoad: '+totalLoad)
                            }
                        }
                    }
                }
                if(totalSuccess == minLoadAccept){
                    r = false;
                    t.clearPrintNoti();
                    console.log('finalizacion de cargas');
                }
                else if(totalLoad== minLoadAccept){
                    t.clearPrintNoti();
                }
                else{ console.log('loadDOM', totalSuccess, totalLoad) }
                t.isLoading = r;
            },
        },
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal", getActionRefresh: "getActionRefresh" }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId" }),
        ...mapGetters("ModApps/billing", ["get_setupBilling"]),
        validOpen() {
            let r = this.modalBill.indexOf(this.m.component) > -1;
            // console.log('validOpen', r)
            return r;
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
