<template>
	<div class="card ">
		<titlebtnsearch :stype="sales_type"></titlebtnsearch>
		<div class="container-fluid card-body" style=" background-color: #f2f4f5;padding-top: 10px;">
			<filtrosContadores :stype="sales_type"></filtrosContadores>
			<tableSales :stype="sales_type"></tableSales>
		</div>
        <!-- modal lateral-->
        <modalLateral>
            <component v-bind:is="m.component" ></component>
        </modalLateral>
        <modal
            name="modal-pop"
            height="400"
            width="300"
        >
            <div class="card-header" style="position: relative">
                <span style="font-size: 18px">{{titlePop}}</span>
                <i
                    class="fas fa-times"
                    style="
                        position: absolute;
                        top: 9px;
                        right: 11px;
                        font-size: 24px;
                        cursor: pointer;
                    "
                    @click="CloseModal"
                ></i>
            </div>
            <div class="card-body">
            	<form @submit.prevent="editSourceCtaDate()">
            		<div class="form-group">
            			<label for="">Source:</label>
            			<select v-model="source_id" class="form-control" disabled="true">
            				<option :value="0">Uncategorized</option>
            				<option v-for="(i, index) in sourceList" :key="index" :value="i.id">{{i.name}}</option>
            			</select>
            		</div>
            		<div class="form-group">
            			<label for="">CTA:</label>
            			<select v-model="cta_id" class="form-control" disabled="true">
            				<option :value="0">Uncategorized</option>
            				<option v-for="(i, index) in ctaList" :key="index" :value="i.id">{{i.name}}</option>
            			</select>
            		</div>
            		<div class="form-group" v-if="salesLeadId==0">
            			<label for="">Date project:</label>
                        <datetime
                            type="datetime"
                            v-model="dateproject"
                            value-zone="America/New_York"
                            input-class="form-control"
                            placeholder="Select date"
                            use12-hour
                            required
							disabled="true"
                        ></datetime>
            		</div>
            		<div class="form-group" v-else>
            			<label for="">Date project:</label>
            			<input type="text" v-model="dateproject_show" class="form-control" disabled="true">
                        
            		</div>
            		<!-- <div class="form-group">
            			<button type="submit" class="btn btn-success">Save</button>
            		</div> -->
            	</form>
            </div>
        </modal>

        <div class="modal-full">
        	<component v-bind:is="viewComponent" :dataSales="dataSales"></component>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral';
import titlebtnsearch from "@/apps/Marketea/components/Sales/title-btn-search.vue";
import filtrosContadores from "@/apps/Marketea/components/Sales/filtrosContadores.vue";
import tableSales from "@/apps/Marketea/components/Sales/tableSales.vue";
import profile from "@/apps/Marketea/components/Sales/profile.vue";
import gallery from "@/apps/Marketea/components/Sales/galleryBox.vue";
import notes from "@/apps/Marketea/components/Sales/notes.vue";
import appointment from "@/apps/Marketea/components/Sales/appointment.vue";
import estimateBilling from "@/apps/Marketea/components/Sales/estimateBilling.vue";
import estimateSelectOne from "@/apps/Marketea/components/Sales/estimateSelectOne.vue";
import openReport from "@/apps/Marketea/components/Sales/openReport.vue";
import sendSMSEst from '@/apps/Marketea/components/Sales/sendSMSEst'
import sendEmailEst from '@/apps/Marketea/components/Sales/sendEmailEst'
import closing from '@/apps/Marketea/components/Sales/closing'
import formProducts from '@/apps/billing/components/formProductSales'
import selectRepSales from '@/apps/Marketea/components/Sales/selectRepSales'
import adjuntarPdf from '@/apps/Marketea/components/Sales/adjuntarPdf'
import adjuntarLinkFiles from '@/apps/Marketea/components/Sales/adjuntarLinkFiles'
import scopeOfWork from "@/apps/Marketea/components/Sales/scopeOfWork.vue";
import SalesContract from "@/apps/Marketea/components/Sales/SalesContract.vue";
import sendSmsContract from "@/apps/Marketea/components/Sales/sendSmsContract.vue";
import contractUploadPdf from "@/apps/Marketea/components/Sales/contractUploadPdf.vue";
import inspSales from "@/apps/Marketea/components/Sales/inspSales.vue";
import inspReportSales from "@/apps/Marketea/components/Sales/inspReportSales.vue";
export default {
	name: 'Sales',
    components: {
        modalLateral,
        titlebtnsearch,
        filtrosContadores,
        tableSales,
        profile,
        gallery,
        notes,
        appointment,
        estimateBilling,
        estimateSelectOne,
        openReport,
		sendSMSEst,
		sendEmailEst,
		closing,
        formProducts,
        selectRepSales,
		adjuntarPdf,
		adjuntarLinkFiles,
		scopeOfWork,
		SalesContract,
		sendSmsContract,
		contractUploadPdf,
		inspSales,
		inspReportSales,
    },
	data () {
		return {
			isLoading: false,
			titlePop: '',
			userlist: [],
			sourceList: [],
			ctaList: [],
			source_id: 0,
			cta_id: 0,
			dateproject: '',
			dateproject_show: '',
			idSale: 0,
			salesLeadId: 0,
            m: {
                show: false,
                component: '',
                data: []
            },
            modalBill: [
                'NotesAdmin',
                'formCustomer',
                'formProducts',
                'StartEstimate',
            ],
			tabs: 1,
			indexTabs: 0,
			tabsList: [
				{value:["1"], name: "Leads", count: 0},
				{value:["2"], name: "Qualification", count: 0},
				// {value:["3"], name: "Appointment", count: 0},
				{value:["10"], name: "Inspection", count: 0},
				{value:["9"], name: "Contract", count: 0},
				// {value:["11"], name: "Claim", count: 0},
				// {value:["5"], name: "Closing", count: 0},
				{value:["6"], name: "Closing Claim", count: 0},
				{value:["7", "11"], name: "Accepted/Rejected", count: 0},
				// {value:["6"], name: "Sold", count: 0},
				// {value:["7"], name: "Rejected", count: 0},
				{value: ["0", "1", "2", "6", "10", "11", "9"], name: "All", count: 0},
			],
			btnsFill: 0,
			btnsFillList: [
				{value:"0", name: "All Active", count: 0},
				{value:"1", name: "Missed Opportunities", count: 0},
				{value:"2", name: "Trash", count: 0},
			],
			listSales: [],
			statusActive: null,
			url: null,
			accessBillingApp: false,
			viewComponent: '',
			dataSales: {},
			uncompleted: false,
			PlanPremium: false,
			planActual: 0,
			planesPro: [],
			isOwner: false,
			estimates_ids: [],
            sales_type: 'insurance',
		}
	},
	created(){
		// this.PlanPremium = (this.$store.getters['core/apps/getPlanIdApp']==6);
		this.planesPro = this.$store.getters['ModApps/Marketea/get_planesPro'];
		this.PlanPremium = (this.planesPro.indexOf(this.$store.getters['core/apps/getPlanIdApp'])>-1);
		this.PlanPro = (this.$store.getters['core/apps/getPlanIdApp']==10);
		// console.log('PlanPremium', this.PlanPremium);
		this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
		if(this.planActual==7){
			this.PlanPremium = true;
			// console.log('Plan sponsored, dando permiso de premium', this.PlanPremium);
		}
		if(this.PlanPremium){
			this.isOwner = (window.user_type==0);
			this.getUsersList();
			this.getSalesList();
			this.getSalesCount();
			this.getSalesCRM();
			this.validBillingApp();
		}
		else{
			this.changePlanApp();
		}
	},
	methods: {
        changePlanApp(){
            let t = this;
            t.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                title: 'This feature is available for PREMIUM USERS.',
                text: 'Upgrade your App to Premium and advanced features, Please contact your administrator at support@marketea.com or Call us at 774-287-3353.',
                duration: 15000
            });
            let appData = {
                app_id: 8,
                show: true,
                infoApp: null,
                planes: [],
            }
            t.$store.commit("core/set_showSuscription", appData);
        },
		openPopFull(c, i){
			let t = this;
			t.viewComponent = c;
			t.dataSales = i;
            document.querySelector(".modal-full").classList.add('show');
            document.querySelector("body").classList.add('noOverflow');
		},
		CloseModalPopFull(){
			let t = this;
			t.viewComponent = '';
			t.dataSales = 0;
            document.querySelector(".modal-full").classList.remove('show');
            document.querySelector("body").classList.remove('noOverflow');
		},
		noBilling(){
            this.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                title: 'Warning',
                text: 'You do not have the Smart Office application installed, contact your system administrator',
                duration: 10000
            });
		},
		validBillingApp() {
			let t = this;
			t.$store.dispatch("core/apps/validAccess").then((response) => {
				let AppBilling = response;
				console.log('validBillingApp', AppBilling)
				if (AppBilling) {
					let appsorg =this.$store.getters['core/apps/applistorgs'];
					t.accessBillingApp=true;
				}
			});
		},
		newProject(){
			this.m= {
                show: true,
                component: 'profile',
                data: []
            };
            this.$store.commit('core/setOpenModal', this.m);
		},
		navTabs(i, idx){
			this.tabs = i;
			this.indexTabs = idx;
			this.getSalesList();
		},
		navTabsFill(i){
			this.btnsFill = i;
		},
		uncompletedActive(){
			console.log('uncompleted', this.uncompleted)
			this.getSalesList(1);
		},
		getSalesList(load){
			let t = this;
			if(!this.PlanPremium){
				this.changePlanApp();
			}else{
				//t.tabs
				if(!load){
					t.isLoading=true;
					t.listSales=[];
				}
				let idStatus = t.tabsList[t.indexTabs].value;
				if(idStatus==0){idStatus=[0, 1, 2, 6, 7, 10, 9, 11];}
				else if(t.uncompleted){idStatus=[t.tabsList[t.indexTabs].value[0], 8];}
				console.log('idStatus', idStatus)
				let dataFiler = {
					org_id: window.localorgdata,
					user_id: window.master_client,
					status: idStatus,
					sales_type: t.sales_type,
				};
				let appID = t.$store.getters["core/apps/getAppIDactual"];
				let appRolUser = t.$store.getters["core/apps/get_appRolUser"];
				let validAdminApp = appRolUser.filter(r => {
					if(appID==r.app_id && r.rol==1){
						return r;
					}
				});
				// console.log('validAdminApp', validAdminApp)
				if(!t.isOwner && validAdminApp.length==0){
					// console.log('is user assign')
					dataFiler.seller_ids = window.localAccountID;
				}
				// else if(t.isOwner){console.log('is Owner')}
				// else {console.log('is admin')}
				window.master.post("getSales", dataFiler)
				.then((response) => {
					t.url=response.data.url;
					let list = [];
					let estIds = [];
					if(response.data.status){
						let e = response.data.getList.data;
						for (let i in e) {
							if(e[i]){
								if(e[i].source.img != null && e[i].source.img!=0) {
									e[i].source.image = window.urlmaster+e[i].source.img.slice(1, e[i].source.img.length);
								}
								if(e[i].cta.img != null && e[i].cta.img!=0) {
									e[i].cta.image = window.urlmaster+e[i].cta.img.slice(1, e[i].cta.img.length);
								}
								e[i].seller = t.printSeller(e[i]);
								e[i].insp = t.printInsp(e[i]);
								if(e[i].est_id && e[i].est_id>0){
									estIds.push(e[i].est_id);
									e[i].estimate=null;
								}
							}
						}
						list = e;
					}
					if(!load){t.isLoading = false;}
					if(estIds.length>0){
						t.getEstIds(estIds, list, load);
					}else{
						t.listSales = list;
					}
					t.salesWithEst();
				})
				.catch((error) => {
					console.log(error);
					if(!load){t.isLoading = false;}
				});
			}
		},
		getSalesCount(){
			let t = this;
			let dataFiler = {
				org_id: window.localorgdata,
				user_id: window.master_client,
                sales_type: t.sales_type,
			};
			// if(!t.isOwner){ dataFiler.seller_ids = window.localAccountID; }
			let appID = t.$store.getters["core/apps/getAppIDactual"];
			let appRolUser = t.$store.getters["core/apps/get_appRolUser"];
			let validAdminApp = appRolUser.filter(r => {
				if(appID==r.app_id && r.rol==1){
					return r;
				}
			});
			if(!t.isOwner && validAdminApp.length==0){
				dataFiler.seller_ids = window.localAccountID;
			}
			window.master.post("getSalesCounts", dataFiler)
            .then((response) => {
        		let count =response.data.count;
        		if(count.length>0){	
        			t.tabsList[0].count=count[0].leads;
					t.tabsList[1].count=count[0].qualification;
					t.tabsList[2].count=count[0].inspection;
					t.tabsList[3].count=count[0].contract;
					t.tabsList[4].count=count[0].sold;
					t.tabsList[5].count=[count[0].rejected, count[0].accepted];
					let all = t.tabsList[0].count+t.tabsList[1].count+t.tabsList[2].count+t.tabsList[3].count+t.tabsList[4].count+count[0].rejected+count[0].accepted;
					t.tabsList[6].count=all;
        		}
			})
            .catch((error) => {
            	console.log(error);
            });
		},
        getEstIds(estIds, list, load){
            let t = this;
			if(!load){t.isLoading = true;}
            window.billingSales.post("estimate/getEstIds", {
                org_id: window.localorgdata,
				estimates_ids: estIds,
            })
            .then((response) => {
                console.log('estimate/getEstIds', response.data.result)
                if(response.data.result.length>0){
					let est = response.data.result;
					list.filter(i => {
						est.filter(r => {
							if(r.id==i.est_id){
								i.estimate = r;
							}
						});
					});
					t.listSales = list;
					console.log('listSales', t.listSales);
				}else{
					t.listSales = list;
				}
				if(!load){t.isLoading = false;}
            })
            .catch((error) => {
                console.log(error);
				if(!load){t.isLoading = false;}
            });
        },
		salesWithEst(){
            let t = this;
			console.log('get salesWithEst')
            window.master.post("salesWithEst", {
				user_id: window.master_client,
                org_id: window.localorgdata,
            })
            .then((response) => {
                console.log('salesWithEst', response.data.r)
                if(response.data.r.length>0){
					t.estimates_ids = response.data.r;
					console.log('estimates_ids', t.estimates_ids);
				}
            })
            .catch((error) => {
                console.log(error);
            });
		},
		getSalesCRM(){
			let t = this;
			window.master.post("getSalesCRM", {
                org_id: window.localorgdata,
                user_id: window.master_client,
            })
            .then((response) => {
				t.sourceList = response.data.source;
				t.ctaList = response.data.cta;
			})
            .catch((error) => {
            	console.log(error);
            });
		},
        activeStatus(id) {
            if (this.statusActive == id) {
                this.statusActive = null;
            } else {
                this.statusActive = id;
            }
            console.log('activeStatus', this.statusActive)
        },
        statusChange(status, id, lead_id, tab) {
            let t =this;
            t.statusActive = null;
            window.master.post('setStatusSales', {
                org_id: window.localorgdata,
                user_id: window.master_client,
                id: id, // sales_id
                status: status,
                lead_id: lead_id,
                tab: tab || t.indexTabs,
            })
            .then((response) => {
                console.log('statusChange', response.data)
                if(response.data.status){
	                t.$notify({
	                    group: "noti",
	                    type: "success",
	                    title: "Successful change",
	                    text: "Status update",
	                });
	                //t.getSalesList();
                }
            })
            .catch((error) => {console.log(error)});
        },
        openModal(c, i){
			if(i.status == 8){
				// si esta en trash no dejar editar
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '5', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Warning',
					text: 'To edit you must Restore this record.',
					duration: 10000
				});
			}
			else{
				this.m= {
					show: true,
					component: c,
					data: i
				};
				if(c=='profile'){ this.m.data={item: i, valid: false}; }
				if(c=='selectRepSales'){
					this.m.data={item: i, userlist: this.userlist};
					this.$store.commit('core/setOpenModal', this.m);
				}
				else if(c=='inspReportSales' || c=='inspSales'){
					if(this.validStatus(i.status, 2, "Qualification missing", "You must qualify before continuing.")){
							// solo despues de qualification
							if(c=='inspSales'){this.m.data={item: i, userlist: this.userlist};}
							this.$store.commit('core/setOpenModal', this.m);
						}
				}
				else if(c=='SalesContract' || c=='contractUploadPdf'){
					this.m.data={item: i, valid: true};
					// if(c=='contractUploadPdf'){
					// 	if(i.contract==0 || !i.insurance_co){
					// 		this.m.component='profile';
					// 		this.$store.commit('core/setNotiPush', {
					// 			show: true,
					// 			group: "notiPush",
					// 			type: '2', // 1=success|2=info|3=error|4=loading|5=warning
					// 			title: 'Info',
					// 			text: 'Fill out "Insurance Info" before to create a Contract.',
					// 			duration: 10000
					// 		});
					// 	}
					// 	this.$store.commit('core/setOpenModal', this.m);
					// }
					if(this.validPefil(i.perfil)){
						// if(this.validStatus(i.status, 11, "Inspection Report", "You must complete the Report.")){
						// 	// solo despues de asignar inspector
						// }
						if(c=='SalesContract'){this.m.component='profile';}
						this.$store.commit('core/setOpenModal', this.m);
					}
				}
				else{
					this.$store.commit('core/setOpenModal', this.m);
				}
			}
        },
        modalChosseEstimate(componentName, i, estIds){
			this.m= {
                show: true,
                component: componentName,
                data: {item: i, estIds: estIds}
            };
            this.$store.commit('core/setOpenModal', this.m);
        },
        openPop(i, title) {
        	let t = this;
        	t.titlePop = title;
        	t.idSale= i.id;
        	t.source_id= i.source.id;
			t.cta_id= i.cta.id;
			t.dateproject= i.dateproject;
			t.dateproject_show= i.dateproject_show;
			t.salesLeadId= i.perfil.lead_id;
            t.$modal.show("modal-pop");
        },
        CloseModal() {
        	let t =this;
            t.$modal.hide("modal-pop");
            t.idSale=0;
			t.source_id=0;
			t.cta_id=0;
			t.salesLeadId=0;
			t.dateproject='';
			t.dateproject_show='';
        },
        editSourceCtaDate(){
        	let t =this;
            window.master.post('setSourceCtaDate', {
                org_id: window.localorgdata,
                user_id: window.master_client,
                idSale: t.idSale,
				source_id: t.source_id,
				cta_id: t.cta_id,
				dateproject: t.dateproject,
				salesLeadId: t.salesLeadId,
            })
            .then((response) => {
                console.log('statusChange', response.data);
                t.CloseModal();
            })
            .catch((error) => {console.log(error)});
        },
        actionPdf(id) {
            window.open(window.urlbase + "api/v2/estimate/pdf/" + id, "_blank");
        },
        qualityPoints(i){
        	let t = this;
			if(i.status == 8){
				// si esta en trash no dejar editar
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '5', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Warning',
					text: 'To edit you must Restore this record.',
					duration: 10000
				});
			}
			else{
				window.localStorage.setItem('qualityIdSales', i.id);
				window.localStorage.setItem('qualityPoints', i.quality_points);
				window.localStorage.setItem('qualityID', i.quality_id);
				console.log('enviando qualityPoints', i.quality_points);
				setTimeout(function(){
					t.sendQualityPoints(i.quality_points, i.status);
				}, 500);
			}
        },
        sendQualityPoints(qualityPoints, status){
        	let t = this;
        	if(window.localStorage.getItem('qualityPoints') == qualityPoints){
	        	let idSales = window.localStorage.getItem('qualityIdSales');
	        	let points = window.localStorage.getItem('qualityPoints');
	        	let Qid = window.localStorage.getItem('qualityID');
	    		console.log('accion actual qualityPoints', idSales, Qid, points);
	            window.master.post('salesSetQualifi', {
	                org_id: window.localorgdata,
	                user_id: window.master_client,
	                idSale: idSales,
	                status: status,
					id: Qid,
					points: points,
					action: 'points',
					tab: t.indexTabs
	            })
	            .then((response) => {
	                console.log('statusChange', response.data);
	        		window.localStorage.removeItem('qualityIdSales');
	        		window.localStorage.removeItem('qualityPoints');
	        		window.localStorage.removeItem('qualityID');
	            })
	            .catch((error) => {console.log(error)});
	        }
        },
		estSendBy(idSale, idVal){
        	let t =this;
			let name = (idVal==1) ? 'Email' : ((idVal==2) ? 'SMS Text' : 'P2P');
			// t.$swal({
			// 	title: "Do you want to confirm sending by ("+name+") continue?",
			// 	text: "Once confirmed you will not be able to return this change, click YES to continue or CANCEL to deny this action.",
			// 	icon: "warning",
			// 	showCancelButton: true,
			// 	confirmButtonColor: "#3085d6",
			// 	confirmButtonText: "Yes, I send!",
			// }).then((result) => {
			// 	if (result.value) {
					window.master.post('salesEstSendBy', {
						org_id: window.localorgdata,
						user_id: window.master_client,
						idSale: idSale,
						est_send_by: idVal,
						"action": 'actListSalesTabs',
						"tab": 4, // index de closing
					})
					.then((response) => {
						console.log('statusChange', response.data);
						t.CloseModal();
					})
					.catch((error) => {console.log(error)});
			// 	}
			// });
        },
        OpenSendSMS(i){
            let t = this;
			t.m = {
				show: true,
				component: 'sendSMSEst',
				data: {
					idSale: i.id,
					estimate: i.est_id,
					typeDocumentID: 0,
				}
			}
			t.$store.commit('core/setOpenModal', this.m);
        },
        OpenSmsContract(i){
            let t = this;
			t.m = {
				show: true,
				component: 'sendSmsContract',
				data: i
			}
			t.$store.commit('core/setOpenModal', this.m);
        },
        OpenSendEmail(i) {
            let t = this;
            this.m = {
                show: true,
                component: 'sendEmailEst',
				data: {
					idSale: i.id,
					estimate: i.est_id,
					typeDocumentID: 0,
				}
            };
            this.$store.commit('core/setOpenModal', this.m);  
        },
        OpenClosing(i, opt) {
            let t = this;
			if(opt==1 || opt==2){
				t.m = {
					show: true,
					component: 'closing',
					data: {
						item: i,
						opt: opt
					}
				};
				t.$store.commit('core/setOpenModal', t.m);
			}else{
				// aceptar estimate y cambiar a status sold
				window.master.post('salesEstStatus', {
					org_id: window.localorgdata,
					user_id: window.master_client,
					sales_id: i.id,
					est_id: i.est_id,
					est_status: 2, //accpeted
					"action": 'actListSalesTabs',
					"tab": 5, // index de sold
				})
				.then((response) => {
					console.log('salesEstStatus', response.data);
				})
				.catch((error) => {console.log(error)});
			}
        },
		getUsersList() {
			let t = this;
			let colors = t.$store.getters['core/get_paletaDeColores'];
			let url = "/users/" + window.master_client;
			window.master.post('usersListSec', {
				user_id: window.master_client,
				org_id: window.localorgdata,
			})
			.then((response) => {
				let r = response.data;
				t.userlist = [{
					id: r.org.id,
					account_id: r.org.account_id,
					owner: r.org.bill_company_name,
					firstname: r.org.bill_firstname,
					lastname: r.org.bill_lastname,
					email: r.org.bill_email,
					pref_name: r.org.pref_name + ' ' + r.org.pref_lastname,
					pref_email: r.org.pref_email,
					classColor: (r.org.color) ? ((r.org.color != '') ? (r.org.color) : '#031099') : '#031099',
					avatar: null,
				}];
				for (let i in r.users) {
					if (r.users[i]) {
						t.userlist.push({
							id: r.users[i].id,
							account_id: r.users[i].account_id,
							owner: r.org.bill_email,
							firstname: r.users[i].firstname,
							lastname: r.users[i].lastname,
							email: r.users[i].email,
							pref_name: r.users[i].pref_name + ' ' + r.users[i].pref_lastname,
							pref_email: r.users[i].pref_email,
							classColor: (r.users[i].color) ? ((r.users[i].color != '') ? (r.users[i].color) : colors[i]) : colors[i],
							avatar: (r.users[i].avatar) ? r.users[i].ruta + r.users[i].avatar : null,
						});
					}
				}
				//console.log('usersListSec', t.userlist)
			})
			.catch((error) => {
				console.log(error);
			});
		},
		printSeller(i){return this.userlist.find(r => { return r.account_id == i.seller_ids});},
		printInsp(i){let r= this.userlist.find(r => { return r.account_id == i.insp_users}); console.log('printInsp', r); return r;},
		setStatusContract(i, status){
			let t= this;
			window.master.post('salesSetStatusContract', {
				org_id: window.localorgdata,
				user_id: window.master_client,
				sales_id: i.id,
				contract_id: i.contract_id,
				status: status,
			})
			.then((response) => {
				console.log('salesSetStatusContract', response.data);
				if(!response.data.status){
					if(!response.data.file){
						t.$store.commit('core/setNotiPush', {
							show: true,
							group: "notiPush",
							type: '5', // 1=success|2=info|3=error|4=loading|5=warning
							title: 'Warning',
							text: 'No contract file found, You must upload one to complete this operation',
							duration: 10000
						});
					}else{
						t.$store.commit('core/setNotiPush', {
							show: true,
							group: "notiPush",
							type: '3', // 1=success|2=info|3=error|4=loading|5=warning
							title: 'Error',
							text: 'Could not confirm Contract',
							duration: 10000
						});
					}
				}
			})
			.catch((error) => {console.log(error)});
		},
        validPefil(p){
			let t = this;
            if(!t.isValid(p.name) ||
            !t.isValid(p.lastname) ||
            !t.isValid(p.phone) ||
            !t.isValid(p.email) ||
            !t.isValid(p.address) ||
            !t.isValid(p.city) ||
            !t.isValid(p.state) ||
            !t.isValid(p.zip)){
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Incomplete profile",
                    text: "You must complete the customer information",
                });
				return false;
            }
			return true;
        },
		validStatus(status, valid, titleAlert, msgAlert){
			console.log('status, valid', status, valid)
			let t = this;
			if(status<valid){
				t.$notify({
                    group: "noti",
                    type: "error",
                    title: titleAlert,
                    text: msgAlert,
                });
				return false;
			}
			return true;
		},
        isValid(value) {
            let r = false;
            if (value === undefined || value === null) {
                r =false;
            }
            if (typeof value === 'string') {
                value = value.trim();
                r = (value.length > 0 && value !== 'null');
            }
            console.log('isValid', value, r);
            return r;
        },
	},
	watch: {
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined) {
                let token = localStorage.getItem('access_token_m');
                let oauth_m = localStorage.getItem('oauth_m');
                if(t.getPusher.action == "actListSales" && t.getPusher.data.org_id == window.localorgdata){
					if(t.getPusher.token != token && t.getPusher.oauth != oauth_m){
						if(t.getPusher.data.action){
							if(t.getPusher.data.action=="actListSalesTabs"){
								console.log('action', t.getPusher.data.action);
								t.getSalesCount();// actualizar contadores
								let tabVal = t.tabsList[t.getPusher.data.tab].value[0];
								let tabIdx = t.getPusher.data.tab;
								t.navTabs(tabVal, tabIdx); // actualizar tab y lista
							}else{
								console.log('actListSales', t.getPusher);
								t.getSalesList(true); // solo actualizar lista
								if(t.getPusher.data.action=='Estimate-accepted'){
									t.getSalesCount();// actualizar contadores
									let tabVal = t.tabsList[4].value[0]; // cambiar a closing
									let tabIdx = 4;
									t.navTabs(tabVal, tabIdx); // actualizar tab y lista
								}
							}
						}else{
							console.log('actListSales', t.getPusher);
                    		t.getSalesList(true); // solo actualizar lista
						}
					}
                }
            }
        },
		getOpenModal: function () {
			console.log('sales watch getOpenModal', this.getOpenModal);
			if (!this.getOpenModal.show) {
				document.querySelector('body').classList.remove('overflowYhide');
				if(this.getOpenModal.data.action){
					if(this.getOpenModal.data.action=='sendSMSEst'){
						this.estSendBy(this.getOpenModal.data.idSale, 2);
					}
					if(this.getOpenModal.data.action=='sendEmailEst'){
						this.estSendBy(this.getOpenModal.data.idSale, 1);
					}
				}
			}
		},
		getAppointmente: function(){
			this.getSalesList(true);
		},
    },
    computed: {
        ...mapGetters("core", {
			getPusher: "get_accitonPusher",
            getOpenModal: 'getOpenModal',
        }),
		...mapGetters("ModApps/Marketea", {
			"getAppointmente": "get_itemSalesAppointmente",
		}),
    }
}
</script>
<style lang="scss" scoped>
.modal-full {
    display: none;
    width: 0px;
    right: 0px;
    position: fixed;
    height: 100%;
    z-index: 9;
    background: rgb(0 0 0 / 50%);
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
}
.modal-full.show {
    display: block;
    position: fixed;
    top: 0px;
    width: 100%;
}
</style>
