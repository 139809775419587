// conexiones
import Servers from './urlServers';
import './varsGlobals';
import axios from 'axios';
let host = Servers;
let cnx = 'produccion'; // valores = produccion | test | dev
window.urlmaster = host.urlmaster[cnx];
window.urlproject = host.urlproject[cnx];
window.urlbilling = host.urlbilling[cnx];
window.urldashboard = host.urldashboard[cnx];
window.urlbaselogin = host.urlbaselogin[cnx];
window.urlbase = host.urlbase[cnx];
window.urlweb = host.urlweb[cnx];
window.urlSlabs = host.urlSlabs[cnx];
window.urlDisplay = host.urlDisplay[cnx];
window.urlbSite = host.urlbSite[cnx];
window.urlbSales = host.urlbSales[cnx];

window.masterApiv2 = axios.create({
  baseURL: window.urlmaster + 'api/v2/'
});

window.master = axios.create({
  baseURL: window.urlmaster + 'api/v3/',
  headers: {
    common: {
      Authorization: 'Bearer ' + window.localtoken_M
    },
    post: { 'Content-Type': 'application/json' }
  }
});
window.masterGetBilling = axios.create({
  baseURL: window.urlmaster + 'api/getBilling/',
  headers: {
    common: {
      Authorization: 'Bearer ' + window.localtoken_M
    },
    post: { 'Content-Type': 'application/json' }
  }
});
window.project = axios.create({
  baseURL: window.urlproject + 'api/v3/',
  headers: {
    common: {
      Authorization: 'Bearer ' + window.localtoken_P
    },
    post: { 'Content-Type': 'application/json' }
  }
});
window.SlabsV2 = axios.create({
  baseURL: window.urlSlabs + 'api/v2/'
});
window.Slabs = axios.create({
  baseURL: window.urlSlabs + 'api/v3/',
  headers: {
    common: {
      Authorization: 'Bearer ' + window.localtoken_slabs
    },
    post: { 'Content-Type': 'application/json' }
  }
});
window.projectV2 = axios.create({
  baseURL: window.urlproject + 'api/v2/'
});

window.apiv2 = axios.create({
  baseURL: window.urlbilling + 'api/v2/'
});
window.billing = axios.create({
  baseURL: window.urlbilling + 'api/v3/',
  headers: {
    common: {
      Authorization: 'Bearer ' + window.localtoken,
      oauthB: localStorage.getItem('oauth'),
      orgID: localStorage.getItem('mark_client_data')
    },
    post: { 'Content-Type': 'application/json' }
  }
});

window.billingMP = axios.create({
  baseURL: window.urlbilling + 'api/MP/',
  headers: {
    common: {
      Authorization: 'Bearer '
    },
    post: { 'Content-Type': 'application/json' }
  }
});

window.reselleR1 = axios.create({
  baseURL: window.urlmaster + 'api/r1/'
});
window.reselleR2 = axios.create({
  baseURL: window.urlmaster + 'api/r2',
  headers: {
    common: {
      Authorization: 'Bearer ' + window.localtoken_r,
      oauth_r: localStorage.getItem('oauth_r'),
    },
    post: { 'Content-Type': 'application/json' }
  }  
});

window.billingSales = axios.create({
  baseURL: window.urlbilling + 'api/sales/',
  headers: {
    common: {
      Authorization: 'Bearer '
    },
    post: { 'Content-Type': 'application/json' }
  }
});
